import ButtonText from 'components/ButtonText/ButtonText';
import ClientOnlyPortal from 'components/Portal/ClientOnlyPortal';
import { APP_CONTENT_ID } from 'constants/app';
import { MouseEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { EnergyLabel as EnergyLabelType, ProductFiche } from 'types/Product';
import getEnergyLabelColor from '../../../utils/energyLabelUtil';
import { isPDF } from '../../../utils/urlUtil';
import Typography from '../../Typography/Typography';
import IFrameOverlay from '../IFrameOverlay/IFrameOverlay';
import {
  StyledEnergyLabelNewEnergyLabel,
  StyledEnergyLabelNewEnergyLabelWrap,
  StyledEnergyLabelWrapper,
  StyledNewEnergyLabelIcon,
  StyledNewEnergyLabelValue,
  StyledOldEnergyLabelIcon,
  StyledOldEnergyLabelValue,
} from './EnergyLabel.styled';

export interface StyledStylingProps {
  color?: string; // hex codes for different energy label colors
  flipHorizontal?: boolean;
}

interface EnergyLabelProps extends StyledStylingProps {
  className?: string;
  energyLabel?: EnergyLabelType;
  productFiche?: ProductFiche;
  useNewEnergyLabel?: boolean;
}

const EnergyLabel = ({
  className,
  energyLabel,
  flipHorizontal,
  productFiche,
  useNewEnergyLabel = false,
}: EnergyLabelProps) => {
  const { formatMessage } = useIntl();

  const [showEnergyLabelOverlay, setShowEnergyLabelOverlay] = useState(false);
  const [showEnergyLabelFicheOverlay, setShowEnergyLabelFicheOverlay] = useState(false);

  if (!energyLabel || !Object.keys(energyLabel)?.length) {
    return null;
  }

  const energyLabelCode = energyLabel?.energyCode;
  const energyLabelUrl = energyLabel?.detailedEnergyLabel?.url || energyLabel?.productEnergySheet?.url;
  const energyLabelFicheUrl = productFiche?.url || productFiche?.media?.url;

  const energyLabelColor = getEnergyLabelColor(energyLabelCode, useNewEnergyLabel);

  const isEnergyLabelPDF = isPDF(energyLabelUrl);

  const isProductFichePDF = isPDF(energyLabelFicheUrl);

  const toggleEnergyLabelOverlay = (event?: MouseEvent) => {
    event && event.preventDefault();
    if (energyLabelUrl) {
      if (showEnergyLabelFicheOverlay) {
        setShowEnergyLabelFicheOverlay(false);
      }
      setShowEnergyLabelOverlay(!showEnergyLabelOverlay);
    }
  };

  const toggleEnergyLabelFicheOverlay = () => {
    if (energyLabelFicheUrl) {
      if (showEnergyLabelOverlay) {
        setShowEnergyLabelOverlay(false);
      }
      setShowEnergyLabelFicheOverlay(!showEnergyLabelFicheOverlay);
    }
  };

  const iFrames = [
    {
      hasPdfExtension: isEnergyLabelPDF,
      show: showEnergyLabelOverlay,
      toggleFunction: toggleEnergyLabelOverlay,
      url: energyLabelUrl,
    },
    {
      hasPdfExtension: isProductFichePDF,
      show: showEnergyLabelFicheOverlay,
      toggleFunction: toggleEnergyLabelFicheOverlay,
      url: energyLabelFicheUrl,
    },
  ];

  return (
    <>
      {iFrames?.map(
        ({ hasPdfExtension, show, toggleFunction, url }, index) =>
          show &&
          url && (
            <ClientOnlyPortal key={index} selector={`#${APP_CONTENT_ID}`}>
              <IFrameOverlay
                key={index}
                className={`energy-label ${hasPdfExtension ? 'pdf' : ''}`}
                iFrameUrl={url}
                show={show}
                title={formatMessage({ id: 'general_energylabel_label_title' }, { code: energyLabelCode })}
                toggleOverlay={() => toggleFunction()}
              />
            </ClientOnlyPortal>
          ),
      )}

      <StyledEnergyLabelWrapper className={className}>
        {useNewEnergyLabel ? (
          <StyledEnergyLabelNewEnergyLabelWrap
            aria-label={formatMessage({ id: 'energy_label_new_label' })}
            onClick={toggleEnergyLabelOverlay}
            type="button"
          >
            <StyledEnergyLabelNewEnergyLabel>
              <StyledNewEnergyLabelIcon
                shouldFlipLetterG={flipHorizontal}
                color={energyLabelColor}
                flipHorizontal={flipHorizontal}
              />
              <StyledNewEnergyLabelValue labelValue={energyLabelCode} />
            </StyledEnergyLabelNewEnergyLabel>
          </StyledEnergyLabelNewEnergyLabelWrap>
        ) : (
          <StyledOldEnergyLabelIcon
            flipHorizontal={flipHorizontal}
            aria-label={formatMessage({ id: 'energy_label_old_label' })}
            color={energyLabelColor}
            onClick={toggleEnergyLabelOverlay}
            role="button"
          >
            <StyledOldEnergyLabelValue color={energyLabelColor}>
              <Typography color="white" fontSize={100} fontWeight="bold">
                {energyLabelCode}
              </Typography>
            </StyledOldEnergyLabelValue>
          </StyledOldEnergyLabelIcon>
        )}

        {energyLabelFicheUrl && (
          <ButtonText onClick={toggleEnergyLabelFicheOverlay}>
            {formatMessage({ id: 'general_energylabel_label' })}
          </ButtonText>
        )}
      </StyledEnergyLabelWrapper>
    </>
  );
};

export default EnergyLabel;
