import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import EnergyLabelIcon2021 from '../../Icon/EnergyLabelIcon2021/EnergyLabelIcon2021';
import EnergyLabelValueIcon from '../../Icon/EnergyLabelValueIcon/EnergyLabelValueIcon';
import { StyledStylingProps } from './EnergyLabel';

export const StyledEnergyLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacer(50)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      margin-bottom: 0px;
    `,
  )}
`;

const OldEnergyLabelTriangleStyling = css<StyledStylingProps>`
  content: '';
  border-color: transparent;
  border-style: solid;
  border-width: ${spacer(100)} ${spacer(100)} ${spacer(100)} 0px;
  height: 0;
  top: 0;
  width: 0;
  border-right-color: ${({ color }) => color};
`;

// Styling Old Energy Label
export const StyledOldEnergyLabelIcon = styled.div<StyledStylingProps>`
  line-height: ${spacer(100)};
  min-width: ${spacer(200)};
  display: inline-flex;

  ${({ flipHorizontal }) =>
    flipHorizontal
      ? css`
          &::after {
            ${OldEnergyLabelTriangleStyling}
            transform: scaleX(-1);
          }
        `
      : css`
          &::before {
            ${OldEnergyLabelTriangleStyling}
          }
        `}

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      margin-bottom: 0px;
    `,
  )}
`;

export const StyledOldEnergyLabelValue = styled.span`
  padding: 0px ${spacer(25)};
  min-width: ${spacer(250)};
  text-align: center;
  line-height: ${spacer(200)};
  background-color: ${({ color }) => color};
`;

// Styling New Energy Label
export const StyledEnergyLabelNewEnergyLabelWrap = styled.button`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 0px;
  border: none;

  &:focus {
    outline: none;
  }
`;

export const StyledEnergyLabelNewEnergyLabel = styled.span`
  position: relative;
  margin-left: auto;
`;

export const StyledNewEnergyLabelIcon = styled(EnergyLabelIcon2021)<StyledStylingProps>`
  height: ${spacer(250)};
  width: auto;
  transform: ${({ flipHorizontal }) => (flipHorizontal ? 'scaleX(-1)' : undefined)};
`;

export const StyledNewEnergyLabelValue = styled(EnergyLabelValueIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
