import dynamic from 'next/dynamic';

const EnergyLabelLetterA = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterA'));
const EnergyLabelLetterB = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterB'));
const EnergyLabelLetterC = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterC'));
const EnergyLabelLetterD = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterD'));
const EnergyLabelLetterE = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterE'));
const EnergyLabelLetterF = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterF'));
const EnergyLabelLetterG = dynamic(() => import('./EnergyLabelLetters/EnergyLabelLetterG'));

interface EnergyLabelValueIconProps {
  labelValue: string;
}

const EnergyLabelValueIcon = ({ labelValue, ...props }: EnergyLabelValueIconProps) => {
  const LABEL_VALUES = {
    A: { icon: <EnergyLabelLetterA {...props} />, value: 'A' },
    B: { icon: <EnergyLabelLetterB {...props} />, value: 'B' },
    C: { icon: <EnergyLabelLetterC {...props} />, value: 'C' },
    D: { icon: <EnergyLabelLetterD {...props} />, value: 'D' },
    E: { icon: <EnergyLabelLetterE {...props} />, value: 'E' },
    F: { icon: <EnergyLabelLetterF {...props} />, value: 'F' },
    G: { icon: <EnergyLabelLetterG {...props} />, value: 'G' },
  };

  if (labelValue.toUpperCase() === LABEL_VALUES.A.value.toUpperCase()) {
    return LABEL_VALUES.A.icon;
  }
  if (labelValue.toUpperCase() === LABEL_VALUES.B.value.toUpperCase()) {
    return LABEL_VALUES.B.icon;
  }
  if (labelValue.toUpperCase() === LABEL_VALUES.C.value.toUpperCase()) {
    return LABEL_VALUES.C.icon;
  }
  if (labelValue.toUpperCase() === LABEL_VALUES.D.value.toUpperCase()) {
    return LABEL_VALUES.D.icon;
  }
  if (labelValue.toUpperCase() === LABEL_VALUES.E.value.toUpperCase()) {
    return LABEL_VALUES.E.icon;
  }
  if (labelValue.toUpperCase() === LABEL_VALUES.F.value.toUpperCase()) {
    return LABEL_VALUES.F.icon;
  }
  if (labelValue.toUpperCase() === LABEL_VALUES.G.value.toUpperCase()) {
    return LABEL_VALUES.G.icon;
  }

  return null;
};

export default EnergyLabelValueIcon;
