export default function getEnergyLabelColor(label: string, isNewLabel = false) {
  switch (label) {
    case 'A+++-60%':
      return '#5FAF49';
    case 'A+++-50%':
      return '#5FAF49';
    case 'A+++-40%':
      return '#5FAF49';
    case 'A+++-30%':
      return '#5FAF49';
    case 'A+++-20%':
      return '#5FAF49';
    case 'A+++-10%':
      return '#5FAF49';
    case 'A+++':
      return '#5FAF49';
    case 'A++':
      return '#49AF81';
    case 'A+':
      return '#A6C450';
    case 'A':
      return isNewLabel ? '#00A650' : '#FEF200';
    case 'B':
      return isNewLabel ? '#4DB848' : '#FFC74B';
    case 'C':
      return isNewLabel ? '#BED62F' : '#EB8C3E';
    case 'D':
      return isNewLabel ? '#FFF200' : '#D0011B';
    case 'E':
      return '#FDB813';
    case 'F':
      return '#F36F21';
    case 'G':
      return '#ed1c24';
    default:
      return '#D0011B';
  }
}
